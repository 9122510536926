import React, { useState, useEffect } from 'react';
import { 
  Table, TableBody, TableCell, TableContainer, TableHead, 
  TableRow, Paper, Button, Typography, Box, TextField,
  TablePagination, CircularProgress
} from '@mui/material';
import { API_BASE_URL } from '../config';
import _ from 'lodash';  // Using lodash for debouncing instead

const LearningVerification = () => {
  const [users, setUsers] = useState([]);
  const [stats, setStats] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalUsers, setTotalUsers] = useState(0);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [verifyingUser, setVerifyingUser] = useState(null);

  const fetchLearningStats = async () => {
    try {
      const response = await fetch(
        `${API_BASE_URL}/api/admin/learning-verification-stats`,
        {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
          }
        }
      );
      
      if (!response.ok) throw new Error('Failed to fetch stats');
      const data = await response.json();
      setStats(data);
    } catch (error) {
      console.error('Error fetching learning stats:', error);
      setError('Failed to load statistics');
    }
  };

  const fetchUnverifiedUsers = async (searchValue = searchTerm) => {
    try {
      setLoading(true);
      setError(null);
      
      const response = await fetch(
        `${API_BASE_URL}/api/admin/unverified-users?` + 
        new URLSearchParams({
          page: page + 1,
          limit: rowsPerPage,
          search: searchValue
        }), {
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`
          }
        }
      );

      if (!response.ok) throw new Error('Failed to fetch users');
      const data = await response.json();
      
      setUsers(data.users);
      setTotalUsers(data.total);
    } catch (error) {
      console.error('Error fetching unverified users:', error);
      setError(error.message);
      setUsers([]);
      setTotalUsers(0);
    } finally {
      setLoading(false);
    }
  };

  // Create a debounced version of fetchUnverifiedUsers
  const debouncedFetch = _.debounce((searchValue) => {
    fetchUnverifiedUsers(searchValue);
  }, 500);

  const handleVerifyUser = async (userId) => {
    try {
      setVerifyingUser(userId);
      const response = await fetch(
        `${API_BASE_URL}/api/admin/writers/${userId}/verify`,
        {
          method: 'POST',
          headers: {
            'Authorization': `Bearer ${sessionStorage.getItem('adminToken')}`,
            'Content-Type': 'application/json'
          }
        }
      );
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to verify user');
      }

      // Refresh both users and stats after successful verification
      await Promise.all([fetchUnverifiedUsers(), fetchLearningStats()]);
      
    } catch (error) {
      console.error('Error verifying user:', error);
      setError(error.message);
    } finally {
      setVerifyingUser(null);
    }
  };

  useEffect(() => {
    fetchLearningStats();
    // Initial fetch of users
    fetchUnverifiedUsers('');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    // When page or rowsPerPage changes, fetch immediately
    if (!searchTerm) {
      fetchUnverifiedUsers('');
    } else {
      debouncedFetch(searchTerm);
    }
    // Cleanup the debounce on unmount
    return () => {
      debouncedFetch.cancel();
    };
    // eslint-disable-next-line
  }, [page, rowsPerPage, searchTerm]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
    setPage(0); // Reset to first page when search changes
  };

  return (
    <Box className="w-full">
      <Typography variant="h4" className="mb-4">Learning Verification</Typography>
      
      {stats && (
        <Box className="mb-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          <Paper className="p-4">
            <Typography variant="h6">Total Writers</Typography>
            <Typography>{stats.totalWriters}</Typography>
          </Paper>
          <Paper className="p-4">
            <Typography variant="h6">Verified Writers</Typography>
            <Typography>{stats.verifiedWriters}</Typography>
          </Paper>
          <Paper className="p-4">
            <Typography variant="h6">Unverified Writers</Typography>
            <Typography>{stats.unverifiedWriters}</Typography>
          </Paper>
          <Paper className="p-4">
            <Typography variant="h6">Unpaid Writers</Typography>
            <Typography>{stats.unpaidWriters}</Typography>
          </Paper>
        </Box>
      )}

      <TextField
        label="Search by name or email"
        variant="outlined"
        fullWidth
        className="mb-4"
        value={searchTerm}
        onChange={handleSearchChange}
      />

      <TableContainer component={Paper} className="shadow-lg">
        <Table className="min-w-[650px]" aria-label="learning verification table">
          <TableHead>
            <TableRow className="bg-primary">
              <TableCell className="text-white font-bold">Name</TableCell>
              <TableCell className="text-white font-bold">Email</TableCell>
              <TableCell className="text-white font-bold">Learning Status</TableCell>
              <TableCell className="text-white font-bold">Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={4} align="center" className="h-32">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : error ? (
              <TableRow>
                <TableCell colSpan={4} className="text-center text-red-500">
                  {error}
                </TableCell>
              </TableRow>
            ) : users.length === 0 ? (
              <TableRow>
                <TableCell colSpan={4} className="text-center">
                  No unverified users found
                </TableCell>
              </TableRow>
            ) : (
              users.map((user) => (
                <TableRow key={user.id}>
                  <TableCell>{user.fullName}</TableCell>
                  <TableCell>{user.email}</TableCell>
                  <TableCell>
                    {user.learningProgress?.isPaid ? 'Paid' : 'Unpaid'}
                  </TableCell>
                  <TableCell>
                    <Button 
                      variant="contained"
                      className="normal-case"
                      onClick={() => handleVerifyUser(user.id)}
                      disabled={
                        verifyingUser === user.id || 
                        !user.learningProgress?.isPaid
                      }
                    >
                      {verifyingUser === user.id ? (
                        <CircularProgress size={24} color="inherit" />
                      ) : (
                        'Verify'
                      )}
                    </Button>
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalUsers}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </TableContainer>
    </Box>
  );
};

export default LearningVerification;
